var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseModal',{ref:"modal",attrs:{"title":"Manuele facturatie","max-width":"tw-max-w-4xl"}},[_c('FormulateForm',{directives:[{name:"show",rawName:"v-show",value:(!_vm.preview),expression:"!preview"}],attrs:{"name":"manualInvoicing"},on:{"submit":_vm.showPreview},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"tw-grid tw-grid-cols-2 tw-gap-x-4"},[_c('FormulateInput',{attrs:{"name":"document_type","label":"Documenttype","type":"select","validation":"required","options":[
              { label: 'Factuur', value: 1 },
              { label: 'Creditnota', value: 2 }
          ],"outer-class":"tw-my-2"}}),_c('FormulateInput',{attrs:{"name":"document_date","label":"Documentdatum","type":"date","placeholder":"YYYY-MM-DD","value":_vm.today.date,"disabled":true,"validation":"bail|required|date:YYYY-MM-DD","outer-class":"tw-my-2"}})],1),_c('div',{staticClass:"tw-grid tw-grid-cols-2 tw-gap-x-4"},[_c('FormulateInput',{attrs:{"name":"company","label":"Facturerende vennootschap","type":"select","placeholder":"Selecteer een vennootschap","validation":"required","options":_vm.companyOptions,"outer-class":"tw-my-2"},on:{"change":_vm.handleCompanyChange}}),_c('FormulateInput',{attrs:{"name":"journal_id","label":"Dagboek","type":"select","placeholder":"Selecteer een dagboek","validation":"required","options":_vm.journals,"disabled":!_vm.values.company,"outer-class":"tw-my-2"}})],1),_c('div',{staticClass:"tw-grid tw-grid-cols-2 tw-gap-x-4"},[_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"invoiceContacts","params":{ is_archived: false },"name":"invoice_recipient_grouping","label":"Facturatiecontact","validation":"required","placeholder":"Zoek op facturatiecontact","outer-class":"tw-mt-2 tw-my-4","data-lpignore":"true"}}),_c('FormulateInput',{attrs:{"name":"posting_description","label":"Boekingsomschrijving","placeholder":"Boekingsomschrijving","validation":"max:35,length","outer-class":"tw-mt-2 tw-my-4"}})],1),_c('hr',{staticClass:"tw-my-4"}),_c('FormulateInput',{attrs:{"type":"group","name":"lines","repeatable":true,"add-label":"+ Nieuwe lijn","remove-label":"Lijn verwijderen","remove-position":"after","validation":"required","group-repeatable-class":['tw-my-1 tw-flex tw-flex-row tw-gap-2 tw-w-full']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var index = ref.index;
return [_c('div',{staticClass:"md:tw-grid tw-grid-cols-8 tw-gap-x-1"},[_c('FormulateInput',{attrs:{"name":"description","type":"text","validation":"required","placeholder":"Verplichte lijnomschrijving","label":index === 0 ? 'Omschrijving' : '',"outer-class":"tw-my-0 tw-col-span-4","data-lpignore":"true"}}),_c('FormulateInput',{attrs:{"name":"general_ledger_account_id","type":"select","placeholder":"Grootboek","validation":"required","options":_vm.generalLedgerAccountOptions,"label":index === 0 ? 'Grootboek' : '',"outer-class":"tw-my-0 tw-col-span-2","data-lpignore":"true"}}),_c('FormulateInput',{attrs:{"name":"vat_code_id","type":"select","validation":"required","placeholder":"Btw","options":_vm.vatCodeOptions,"label":index === 0 ? 'Btw-code' : '',"outer-class":"tw-my-0","data-lpignore":"true"}}),_c('FormulateInput',{attrs:{"name":"amount","type":"number","step":"0.01","validation":"required","placeholder":"Bedrag","label":index === 0 ? 'Bedrag' : '',"outer-class":"tw-my-0","data-lpignore":"true"}})],1)]}},{key:"addmore",fn:function(ref){
          var addMore = ref.addMore;
return [_c('button',{staticClass:"\n              tw-px-2 tw-py-0.5 tw-border tw-mt-2\n              tw-text-success tw-border-success tw-rounded-md tw-shadow-card tw-bg-white\n              hover:tw-bg-success hover:tw-text-white\n              disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed\n            ",attrs:{"type":"button","title":"+ Lijn"},on:{"click":addMore}},[_c('i',{staticClass:"far fa-plus"}),_vm._v(" Lijn ")])]}},{key:"remove",fn:function(ref){
          var index = ref.index;
          var removeItem = ref.removeItem;
return [_c('div',{staticClass:"tw-flex tw-items-end"},[_c('button',{staticClass:"tw-h-10 tw-text-sm tw-rounded tw-text-danger",attrs:{"type":"button","title":"Lijn verwijderen"},on:{"click":function($event){return removeItem(index)}}},[_c('i',{staticClass:"fas fa-trash"})])])]}}],null,true)}),_c('hr'),(_vm.totalAmount)?_c('div',{staticClass:"tw-flex tw-flex-wrap tw-justify-end tw-mt-8 tw-my-4"},[_c('div',{staticClass:"tw-flex tw-flex-wrap tw-justify-between md:tw-w-1/4"},[_c('div',{staticClass:"tw-flex tw-gap-x-4 tw-justify-between tw-w-full"},[_c('span',[_vm._v("Maatstaf van heffing")]),_c('span',{staticClass:"tw-text-right"},[_vm._v(_vm._s(_vm.currency(_vm.netInvoiceAmount)))])]),_c('div',{staticClass:"tw-flex tw-gap-x-4 tw-justify-between tw-w-full"},[_c('span',[_vm._v("Btw-bedrag")]),_c('span',{staticClass:"tw-text-right"},[_vm._v(_vm._s(_vm.currency(_vm.vatAmount)))])]),_c('div',{staticClass:"tw-flex tw-gap-x-4 tw-justify-between tw-w-full tw-font-bold"},[_c('span',[_vm._v("Totaal")]),_c('span',{staticClass:"tw-text-right"},[_vm._v(_vm._s(_vm.currency(_vm.totalAmount)))])])])]):_vm._e(),_c('FormulateErrors',{staticClass:"tw-text-right"}),_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading || (_vm.values.lines && !_vm.values.lines.length),"input-class":['!tw-text-sm']}},[_c('i',{class:[
              'fas tw-mr-2',
              isLoading
                ? 'fa-spinner-third fa-spin'
                : 'fa-eye'
            ]}),_vm._v(" Factuurvoorbeeld laden ")])],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}}),_c('EntityInvoicePreview',{directives:[{name:"show",rawName:"v-show",value:(_vm.preview),expression:"preview"}],ref:"preview",on:{"invoicing-done":_vm.invoicingDone,"hidePreview":_vm.hidePreview}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }